<template>
  <CFooter :fixed="false">
    <div>
      <a href="/" target="_blank">Hihi! 海嗨旅宿</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }} Hihi! 海嗨旅宿.</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Powered by</span>
      <a href="#" target="_blank">SONG TAI Technology.</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
