import i18n from '@/lang/i18n'

export default [
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('dashboard'),
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('system_management')],
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('organization_management'),
    route: '/organization',
    icon: 'cil-people',
    items: [
      {
        name: i18n.t('accounts'),
        to: '/organization/admins',
        icon: 'cil-user',
        role: 'admins',
      },
    ],
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('date_management'),
    route: '/dates',
    icon: 'cil-calendar',
    items: [
      {
        name: i18n.t('booking_dates'),
        to: '/dates/booking-dates',
        icon: 'cil-calendar-check',
        role: 'dates.booking-dates'
      },
      {
        name: i18n.t('holiday_dates'),
        to: '/dates/holiday-dates',
        icon: 'cil-beach-access',
        role: 'dates.holiday-dates'
      },
    ]
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('configs'),
    to: '/configs',
    icon: 'cil-cog',
    role: 'configs',
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('backend_management')],
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('room_management'),
    route: '/rooms',
    icon: 'cil-room',
    items: [
      {
        name: i18n.t('room_setting'),
        to: '/rooms/rooms-setting',
        icon: 'cil-room',
        role: 'rooms.rooms-setting'
      },
      {
        name: i18n.t('lock_room_setting'),
        to: '/rooms/lock-room-setting',
        icon: 'cil-lock-locked',
        role: 'rooms.lock-room-setting'
      },
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('extra_management'),
    route: '/extras',
    icon: 'cil-flight-takeoff',
    items: [
      {
        name: i18n.t('extra_setting'),
        to: '/extras/extras-setting',
        icon: 'cil-boat-alt',
        role: 'extras.extras-setting'
      },
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('order_management'),
    route: '/orders',
    icon: 'cil-cart',
    items: [
      {
        name: i18n.t('order_search'),
        to: '/orders/orders',
        icon: 'cil-list',
        role: 'orders.orders'
      },
      {
        name: i18n.t('order_room_search'),
        to: '/orders/order-rooms',
        icon: 'cil-bed',
        role: 'orders.order-rooms'
      },
      {
        name: i18n.t('order_extra_search'),
        to: '/orders/order-extras',
        icon: 'cil-map',
        role: 'orders.order-extras'
      },
    ]
  },
  // {
  //   _name: 'CSidebarNavItem',
  //   name: i18n.t('order_management'),
  //   to: '/orders',
  //   icon: 'cil-cart',
  //   role: 'orders'
  // },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('content_management'),
    to: '/contents',
    icon: 'cil-pencil',
    role: 'contents'
  },
]
