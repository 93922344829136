<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="40"
        viewBox="0 0 448 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="40"
        viewBox="0 0 150 134"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="navItems"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  data () {
    return {
      navItems: [
        {
          _name: 'CSidebarNav',
          _children: []
        }
      ]
    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },

    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },
  mounted: function () {
    this.navCheckRoles()
  },
  methods: {
    navCheckRoles () {
      const roles = this.$store.getters.roles
      if (roles.includes('root')) {
        this.navItems[0]._children = nav
      } else {
        let chds = nav.filter(function (chd) {
          if (chd.to) {
            return !chd.role || roles.includes(chd.role)
          } else {
            if (chd.items) {
              for (let i in chd.items) {
                if (!chd.items[i].role || roles.includes(chd.items[i].role)) {
                  return true
                }
              }
            } else if (chd._name === 'CSidebarNavTitle') {
              return true
            }
            return false
          }
        })
        let items, ii, del_idx = []
        for (let i in chds) {
          if (!chds[i].to && chds[i].items) {
            items = []
            for (let x in chds[i].items) {
              if (!chds[i].items[x].role || roles.includes(chds[i].items[x].role)) {
                items.push(chds[i].items[x])
              }
            }
            chds[i].items = items
          } else if (chds[i]._name === 'CSidebarNavTitle') {
            ii = parseInt(i)
            if (ii + 1 === chds.length || (ii + 1 < chds.length && chds[ii + 1]._name === 'CSidebarNavTitle')) {
              del_idx.unshift(i)
            }
          }
        }
        for (let i in del_idx) {
          chds.splice(del_idx[i], 1)
        }
        this.navItems[0]._children = chds
      }
    }
  }
}
</script>
